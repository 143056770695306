<template>
  <button
    class="base-button"
    :class="[
      `base-button--${props.variant}`,
      `base-button--${props.size}`,
      `font--${props.font}`,
      {
        'base-button--icon-right': props.iconPosition === 'right',
        'base-button--round': props.round,
      },
    ]"
    v-bind="$attrs"
  >
    <base-icon
      v-if="props.iconName"
      :size="
        props.iconSize ? props.iconSize : props.size === 'lg' ? '24px' : '18px'
      "
      :name="props.iconName"
      :class="[`base-button--${props.variant}`]"
      :filled="props.iconFilled"
    />
    {{ props.text }}
  </button>
</template>

<script setup lang="ts">
interface Props {
  text?: string;
  variant?:
    | "prime"
    | "dark"
    | "light"
    | "light_bordered"
    | "ghost"
    | "dark_ghost"
    | "filled_ghost"
    | "filled_ghost-noborder";
  size?:
    | "xs"
    | "xss"
    | "ls"
    | "sm"
    | "sg"
    | "sl"
    | "lg"
    | "round-sm"
    | "round-xs"
    | "round-ls";
  font?: "h1" | "h2" | "h3" | "h4" | "b1" | "b2" | "b3" | "b4" | "b5";
  iconName?: string;
  iconPosition?: "left" | "right";
  round?: boolean;
  iconSize?: string;
  iconFilled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  variant: "prime",
  size: "sm",
  font: "b2",
  iconPosition: "left",
});
</script>

<style scoped lang="scss">
$borderWidth: 1px;
$transitionDuration: 0.3s;
$transition: background-color $transitionDuration;

@mixin invisible-border {
  border-color: transparent;
  border-style: solid;
  border-width: $borderWidth;
}

@mixin dark {
  @include invisible-border;
  color: var(--white-monochrome);
  background-color: var(--black-monochrome);
  border: none;
  &:hover {
    background: linear-gradient(180deg, #6742d0 0%, #1f0764 100%);
  }
}

@mixin prime {
  color: var(--white-monochrome);
  background-color: var(--violet-main);
  border: none;
  transition: $transition;
  &:hover {
    background-color: var(--purple-main);
  }
}

@mixin light {
  @include invisible-border;
  color: var(--black-monochrome);
  background-color: var(--white-monochrome);
  &:hover {
    border-color: var(--black-monochrome-60);
  }
}
@mixin light_bordered {
  color: var(--black-monochrome);
  background-color: var(--white-contrast);
  border: 1px solid var(--gray-40);
  transition: $transition;
  &:hover {
    background-color: var(--white-transparent);
  }
}

@mixin ghost {
  color: var(--white-monochrome);
  background-color: transparent;
  transition:
    $transition,
    border-color $transitionDuration;
  border-color: var(--white-monochrome);
  border-style: solid;
  border-width: $borderWidth;
  &:hover {
    background-color: var(--white-transparent);
  }
}

@mixin dark_ghost {
  color: var(--black-monochrome);
  background-color: transparent;
  transition:
    $transition,
    border-color $transitionDuration;
  border-color: var(--black-monochrome);
  border-style: solid;
  border-width: $borderWidth;
  &:hover {
    background-color: var(--gray-monochrome-40);
  }
}

@mixin filled_ghost {
  color: var(--black-monochrome-54);
  background-color: var(--black-monochrome-24);
  transition:
    $transition,
    border-color $transitionDuration;
  border-color: var(--black-monochrome-54);
  border-style: solid;
  border-width: $borderWidth;
}

.base-button {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 28px 8px 28px;
  border: none;
  outline: none;
  margin: 0;
  border-radius: 40px;
  white-space: nowrap;
  cursor: pointer;
  &--prime {
    @include prime;
  }
  &--dark {
    @include dark;
  }
  &--light {
    @include light;
  }
  &--ghost {
    @include ghost;
  }
  &--dark_ghost {
    @include dark_ghost;
  }
  &--filled_ghost {
    @include filled_ghost;
  }
  &--light_bordered {
    @include light_bordered;
  }
  &--filled_ghost-noborder {
    @include filled_ghost;
    @include invisible-border;
    transition: scale 0.3s ease;

    &:hover {
      background-color: var(--gray-monochrome-40);
    }
    &:active {
      scale: 0.95;
    }
  }
  &--xs {
    height: 32px;
    padding: 4px 10px 4px 8px;
  }
  &--xss {
    height: 36px;
    padding: 6px 12px;
    text-align: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 6px;
  }
  &--ls {
    height: 40px;
    padding: 12px 32px;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  &--sm {
    height: 41px;
  }
  &--sg {
    height: 41px;
    padding: 8px 16px 8px 12px;
  }
  &--sl {
    height: 49px;
  }
  &--lg {
    height: 53px;
  }
  &--round {
    height: 44px;
    padding: 13px;
    border-radius: 50%;
  }
  &--round-sm {
    padding: 4px;
    height: 24px;
  }
  &--round-xs {
    width: 40px;
    height: 40px;
    padding: 11px;
  }
  &--round-ls {
    width: 60px;
    height: 60px;
  }

  &--icon {
    &-right {
      flex-direction: row-reverse;
    }
  }
}
.base-icon {
  border: none;
  background: transparent !important;

  :deep(.nuxt-icon) {
    svg {
      margin: 0;
    }
  }
}
</style>
